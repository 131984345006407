<template>
  <b-container class="my-2 my-sm-5" fluid>
    <b-row align-v="start" no-gutters>
      <b-col cols="12" class="">
        <h1>{{ $t("dataproviders.pagename") }}</h1>
      </b-col>
      <b-col cols="12" md="7" class="mt-1">
        <p class="text-terciary text">
          {{ $t("dataproviders.description") }}
        </p>
      </b-col>
      <b-col
        style="border-bottom:1px solid #c1c1c1;"
        class="mt-3"
        cols="10"
      ></b-col>
    </b-row>

    <b-overlay :show="loading">
      <b-row class="mt-4" align-v="start" v-if="dataProviders">
        <b-col
          cols="12"
          v-for="(data, index) in dataProviders"
          :key="data.id"
          class="mb-5"
        >
          <div class="d-flex align-items-center">
            <b-img
              class="mb-3"
              v-if="data.file_path && data.file_name"              
              style="object-fit: cover"
              height="50"
              width="50"
              :src="`${data.file_path}${data.file_name}`"
            />
            <h3 class="text-terciary pl-2">
              {{ data.provider_name }}
            </h3>
          </div>
          <b-row no-gutters>
            <b-col cols="12" md="8">
              <p class="text-terciary text">{{ data.description }}</p>
            </b-col>

            <!-- Conditional Rendering for Special Providers -->
            <b-col cols="12" v-if="isSpecialProvider(data.provider_name)">
              <p class="text-danger">
                {{ specialProviderMessage(data.provider_name) }}
              </p>
            </b-col>
            
            <!-- Render buttons for other providers -->
            <template v-else>
              <b-col
                cols="12"
                v-if="data.has_connected === 1"
                class="d-flex flex-column flex-sm-row"
              >
                <b-button
                  variant="primary"
                  class="text-uppercase"
                  style="border-radius:11px"
                  v-if="!data.checksyncLoading"
                  @click="validateConnection(data, index)"
                  >{{ $t("dataproviders.check_connection") }}</b-button
                >
                <b-button
                  style="border-radius:11px"
                  variant="primary"
                  disabled
                  v-if="data.checksyncLoading"
                >
                  <b-spinner small></b-spinner>
                  <span class="sr-only">Loading...</span>
                </b-button>
                <b-button
                  variant="outline-primary"
                  class="text-uppercase ml-0 mt-2 mt-sm-0 ml-sm-3"
                  style="border-radius:11px"
                  v-if="!data.removeConnectionLoading"
                  @click="removeConnection(data, index)"
                  >{{ $t("dataproviders.remove_connection") }}</b-button
                >
                <b-button
                  style="border-radius:11px"
                  class="ml-0 mt-2 mt-sm-0 ml-sm-3"
                  variant="danger"
                  disabled
                  v-if="data.removeConnectionLoading"
                >
                  <b-spinner small></b-spinner>
                  <span class="sr-only">Loading...</span>
                </b-button>
              </b-col>
              <b-col cols="12" v-else>
                <b-button
                  variant="primary"
                  style="border-radius:11px"
                  v-if="!data.spinnerLoading"
                  @click="AuthDataProvider(data.provider_name, index)"
                  >{{ $t("dataproviders.connect") }}</b-button
                >
                <b-button
                  style="border-radius:11px"
                  variant="primary"
                  disabled
                  v-if="data.spinnerLoading"
                >
                  <b-spinner small></b-spinner>
                  <span class="sr-only">Loading...</span>
                </b-button>
              </b-col>
            </template>
          </b-row>
        </b-col>
      </b-row>

    </b-overlay>
  </b-container>
</template>

<script>
import axios from "axios"
export default {
  data() {
    return {
      loading: false,
      dataProviders: null,
    }
  },


  computed: {
    isSpecialProvider() {
      return (providerName) => {
        const normalized = providerName.replace(/\s+/g, '').toUpperCase();
        return ['APPLEHEALTH', 'HEALTHCONNECT'].includes(normalized);
      };
    },
    specialProviderMessage() {
      return (providerName) => {
        const normalized = providerName.replace(/\s+/g, '').toUpperCase();
        switch (normalized) {
          case 'APPLEHEALTH':
            return this.$t("dataproviders.apple_health_message");
          case 'HEALTHCONNECT':
            return this.$t("dataproviders.health_connect_message");
          default:
            return '';
        }
      };
    },
  },  
  methods: {
    async getDataProviders() {
      this.loading = true
      try {
        const response = await axios.get(`/providers`)

        if (response.status === 200) {
          let providers = response.data?.data ?? null
          if (providers && providers.length) {
            providers = providers.map(providersData => {
              return {
                ...providersData,
                spinnerLoading: false,
                checksyncLoading: false,
                removeConnectionLoading: false,
              }
            })
            this.dataProviders = providers
          }
        }
      } catch ({ response }) {
        this.$store.dispatch("addToastMessage", {
          type: "danger",
          text: response.data.error_message,
        })
      } finally {
        this.loading = false
      }
    },

    async AuthDataProvider(provider, index) {
      this.dataProviders[index].spinnerLoading = true
      const provider_name = provider.toLowerCase()

      try {
        const response = await axios.get(`/authprovider/${provider_name}`)
        if (response.status === 200) {
          window.location.href = response.data.data
        }
      } catch ({ response }) {
        this.$store.dispatch("addToastMessage", {
          type: "danger",
          text: response.data.error_message,
        })
      } finally {
        this.dataProviders[index].spinnerLoading = false
      }
    },
    async validateConnection(provider, index) {
      this.dataProviders[index].checksyncLoading = true;
      try {
        let data = {
          provider_id: provider.id,
        };

        const response = await axios.get(`/provider`, { params: data });

        // Check if response contains an error_code
        if (response.data.valid_provider?.original?.error_code === 401) {
          this.$store.dispatch("addToastMessage", {
            type: "danger",
            text: response.data.valid_provider.original.error_message || "Authentication error",
          });
        } else {
          this.$store.dispatch("addToastMessage", {
            type: "success",
            text: "Sync Working",
          });
        }
      } catch (error) {
        // Fallback for network errors or unexpected issues
        this.$store.dispatch("addToastMessage", {
          type: "danger",
          text: error.response?.data?.error_message || "Something went wrong",
        });
      } finally {
        this.dataProviders[index].checksyncLoading = false;
      }
    },
    async removeConnection(provider, index) {
      this.dataProviders[index].removeConnectionLoading = true
      try {
        let data = {
          provider_id: provider.id,
        }

        const response = await axios.delete(`/provider`, {
          params: data,
        })
        if (response.status === 204) {
          this.$store.dispatch("addToastMessage", {
            type: "success",
            text: "Connection removed",
          })
          this.getDataProviders()
        }
      } catch ({ response }) {
        this.$store.dispatch("addToastMessage", {
          type: "danger",
          text: response.data.error_message,
        })
      } finally {
        this.dataProviders[index].removeConnectionLoading = false
      }
    },

    async AuthDataProviderWithCode(provider, query) {
      this.loading = true;
      try {
        let data = {};

        if (provider === "fitbit") {
          data = {
            code: query.code,
            state: query.state,
          };
        } else if (provider === "garmin") {
          data = {
            oauth_token: query.oauth_token,
            oauth_verifier: query.oauth_verifier,
          };
        } else {
          data = {
            code: query.code,
          };
        }

        const response = await axios.get(`/authprovider/${provider}`, {
          params: data,
        });

        if (response.status === 204) {
          this.$store.dispatch("addToastMessage", {
            type: "success",
            text: "Successfully connected",
          });
          this.getDataProviders();
        }
      } catch ({ response }) {
        this.$store.dispatch("addToastMessage", {
          type: "danger",
          text: response?.data?.error_message || "An error occurred",
        });
      } finally {
        this.loading = false;
      }
    }

  },
  mounted() {
    this.getDataProviders()
  },
  created() {
    const url = this.$route
    if (Object.keys(url.params).length && Object.keys(url.query).length) {
      this.AuthDataProviderWithCode(url.params.provider, url.query)
    }
  },
}
</script>

<style scoped>
.text {
  font-size: 14px;
}

@media (min-width: 800px) {
  .text {
    font-size: 15px;
  }
}
@media (min-width: 1200px) {
  .text {
    font-size: 16px;
  }
}
</style>
